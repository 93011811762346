import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import QRCode from "react-qr-code";

export { QRCodeReport };

function QRCodeReport() {
    let ReportPrintContent='';
    const [patientDataReport, setPatientDataReport] = useState([]);

    const location = useLocation();
    let myParam = new URLSearchParams(location.search).get('data');
    let user_key= 0; let center_key = 0; let patient_key = 0;
    
    if(myParam===null){
        alert('Invalid request.');
    }
    else{
        var CryptoJS = require("crypto-js");
        var decryptkey = CryptoJS.enc.Base64.parse(myParam).toString(CryptoJS.enc.Utf8);

        if(decryptkey!=="")
        {
            if(decryptkey.split('~').length>1){
                center_key = decryptkey.split('~')[0];
                patient_key = decryptkey.split('~')[1];
            }   
            else if(decryptkey.split('@').length>1){
                center_key = decryptkey.split('@')[0];
                patient_key = decryptkey.split('@')[1];
            }
        }
    }

    useEffect(() => {
        setTimeout(function () {
            if(patient_key > 0){
                printreport(user_key, center_key, patient_key);
            }
          }, 10);    
    }, []);

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    function printreport(user_key, center_key, patient_key) {
        const url = `${process.env.REACT_APP_API_URL}/patients/GetById`;
        const request_id = randomNumberInRange(1000000, 9000000);

        const config = {
          headers: {
            'content-type': 'application/json',
            'user_key': user_key.toString(),
            'center_key': center_key.toString(),
            'patient_key': patient_key.toString(),
            'request_id': request_id.toString()
          },
        };
        try {
            axios.get(url, config).then((response) => {
                let updatedValue = {};
                updatedValue = response.data; 
                setPatientDataReport(updatedValue);
                
                if(updatedValue !== null){
                    
                    const template = getReportContent(0, updatedValue);
                    var divReport = document.getElementById('divReport');
                    if(divReport!=null){
                        divReport.innerHTML = template;
                    }
                }
            })
           .catch(error => {
            if(error.response.data.message==='Unauthorized')
            {
              alert('Unauthorized session, try to relogin.');
            }
            else{
            
              alert(error.response.data.message);
            }
           });
    
          } catch (err) {
            
            //alert('error');
              //alert(err.code);
            if (err.code === '404') {
              //setError('Object does not exist.')
              return
            }
          }
    
      }
     
    const getReportContent = (index, patientData) =>
    {
      //var reportTemplate="<p>Patient Name: "+patientData.patient_name+". &nbsp;&nbsp;&nbsp;&nbsp; Study Date/Time: "+patientData.regn_date + patientData.regn_time +"<br>Sex/Age/Modality: "+patientData.patient_sex + patientData.patient_age + patientData.modality + " &nbsp;&nbsp;&nbsp;&nbsp; Report Date/Time: "+patientData.updated_on+"<br>Patient ID: "+patientData.patient_id+" &nbsp;&nbsp;&nbsp;&nbsp; Report: CHEST PA<br>Ref. Physician: "+patientData.refer_physician+" Report ID: " + patientData.report_id;

      var report_count = patientData.report_count;
      var report_content= patientData.report_content.replace('<h1>', '<h2>').replace('</h1>', '</h2>');
      var report_content2= patientData.report_content2.replace('<h1>', '<h2>').replace('</h1>', '</h2>');
      var report_content3= patientData.report_content3.replace('<h1>', '<h2>').replace('</h1>', '</h2>');
      var report_content4= patientData.report_content4.replace('<h1>', '<h2>').replace('</h1>', '</h2>');

      var dicomUrl = patientData.dicomUrl;
      var topMarginHeader = dicomUrl.split[0];
      var bottomMarginFooter = dicomUrl.split[1];
      var topMarginBodyArea = dicomUrl.split[2];
      var bottomMarginBodyArea = dicomUrl.split[3];
      var report_template_code = dicomUrl.split[4];
      
      //topMarginHeader = '100'; bottomMarginFooter = '100'; topMarginBodyArea = '250'; bottomMarginBodyArea = '250';
      //topMarginHeader = '80'; bottomMarginFooter = '80'; topMarginBodyArea = '230'; bottomMarginBodyArea = '230';
      
      //report_template_code = 'Template4';
      //report_template_code = '';

      const modality = patientData.modality;
      let cssReportName = 'css/report_template3.css';
      let cssReportLinkRef1 = ``;
      if (modality === 'CT' || modality === 'ct')
      {
        cssReportName = 'css/report_template_ct.css';
        cssReportLinkRef1 = `<link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" rel="stylesheet">`;
      }
      else if(report_template_code === 'Template4'){
        cssReportName = 'css/report_template4.css';
        cssReportLinkRef1 = `<link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Roboto:wght@500;700;900&display=swap" rel="stylesheet">`;
      }

      var sign_url_data = 'profile/dr-diptendu_sign.jpg';
      var dr_name = 'Dr. Diptendu Kumar Biswas';
      var dr_design = 'M.B.B.S., D.M.R.D. (Consultant Radiologist)';
      var dr_regn = 'Registration No: 61828 [WBMC]';

      if (patientData.center_key === 49 || 
        patientData.center_key === 50 ||
        patientData.center_key === 52 ||
        (patientData.center_key >= 61 && patientData.center_key <= 64) ||
          (patientData.patient_key > 19090 &&
            (patientData.center_key === 16
          || patientData.center_key === 40
          || patientData.center_key === 42
          ))
          || (patientData.patient_key > 23610 && patientData.center_key === 41)
          || (patientData.doctor_key === 78 && patientData.center_key === 31)
          || (patientData.doctor_key === 78 && patientData.center_key === 68)
          || (patientData.doctor_key === 78 && 
              (patientData.center_key === 33 || patientData.center_key === 66
                || patientData.center_key === 18
                )
              )
          || (patientData.doctor_key === 78 && patientData.center_key === 59)
          || (patientData.doctor_key === 78 && patientData.center_key > 69)
          || (patientData.doctor_key === 78 && patientData.center_key === 53 && patientData.patient_key > 58775)
          || (patientData.doctor_key === 78 && patientData.center_key === 56 && patientData.patient_key > 58775)
          || (patientData.doctor_key === 78 && patientData.center_key === 60 && patientData.patient_key > 58775)
        )
      {
        sign_url_data = 'profile/78-dr-asif_sign.jpg';
        dr_name = 'Dr. Sk Md Asif Iquebal';
        dr_design = 'MD (Radio-Diagnosis)';
        dr_regn = 'Reg. No.-71264 (WBMC)';
      }

      var template3 = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>`+patientData.patient_name + `-` + patientData.patient_id +`</title>
          <link href="css/font-awesome.min.css" rel="stylesheet">
          `+cssReportLinkRef1+`
          <link href="`+cssReportName+`" rel="stylesheet">
      </head>
      <body>`;

      let patient_age = '';
      if(patientData.patient_age_unit==='Y'){
        patient_age = 'Year';
      }
      else if(patientData.patient_age_unit==='M'){
        patient_age = 'Month';
      }
      else if(patientData.patient_age_unit==='D'){
        patient_age = 'Day';
      }

      if(patient_age !== '' && patientData.patient_age>1){
        patient_age += 's';
      }

      let patient_sex = '';
      if(patientData.patient_sex==='M'){
        patient_sex = 'Male';
      }
      else if(patientData.patient_sex==='F'){
        patient_sex = 'Female';
      }
      
      let reportHeader = '';
      if (modality === 'CT' || modality === 'ct' || report_template_code === 'CT')
      {
        reportHeader =`<thead>
                        <tr>
                          <th style="width:130px;border: 1px solid #000;">Name</td>
                          <th style="width:300px;border: 1px solid #000;">`+patientData.patient_name+`</td>
                          <th style="width:100px;border: 1px solid #000;">Age/Sex</td>
                          <th style="width:150px;border: 1px solid #000;">`+patientData.patient_age.toString()+ patientData.patient_age_unit+`-`+patientData.patient_sex+`</td>
                        </tr>
                        <tr>
                          <th style="border: 1px solid #000;">Date</td>
                          <th style="border: 1px solid #000;">`+patientData.regn_date+`</td>
                          <th style="border: 1px solid #000;">Patient Id</td>
                          <th style="border: 1px solid #000;">`+patientData.patient_id_ref+`</td>
                        </tr>
                        <tr>
                          <th style="border: 1px solid #000;">Referring Doctor</td>
                          <th style="border: 1px solid #000;">`+patientData.refer_physician+`</td>
                          <th style="border: 1px solid #000;">Report Id</td>
                          <th style="border: 1px solid #000;">`+patientData.patient_id+`</td>
                        </tr>
                      </thead>`;
      }
      else if (report_template_code === 'Template4')
      {
        reportHeader =`<div class="topheader" style="margin-top: `+ topMarginHeader + `px;">
                      <div class="tab-container">
                          <table style="width: 900px">
                              <tr>
                              <td style="width:150px;"><i class="fa fa-user-o" aria-hidden="true"></i> Patient</td>
                              <td style="width:400px;"><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patientData.patient_name+`</b></td>
                              <td style="width:150px;"><i class="fa fa-venus-mars" aria-hidden="true"></i> Age</td>
                              <td style="width:200px;"><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patientData.patient_age.toString() + ` ` + patient_age+`</b></td>
                              </tr>
                              <tr>
                              <td><i class="fa fa-refresh" aria-hidden="true"></i> Ref By</td>
                              <td><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patientData.refer_physician+`</b></td>
                              <td><i class="fa fa-venus" aria-hidden="true"></i> Gender</td>
                              <td><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patient_sex+`</b></td>
                              </tr>
                              <tr>
                              <td><i class="fa fa-clock-o" aria-hidden="true"></i> Date</td>
                              <td><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patientData.regn_date+`</b></td>
                              <td><i class="fa fa-file-text" aria-hidden="true"></i> Report Id</td>
                              <td><i class="fa fa-caret-right iconcol" aria-hidden="true"></i>&nbsp; <b>`+patientData.patient_id+`</b></td>
                              </tr>
                          </table>
                      </div>
                  </div>`;
      }
      else{
        var col1_width = '130';
        var col2_width = '300';
        var col3_width = '100';
        var col4_width = '150';

        if(patientData.center_key === 39)
        {
          col2_width = '255';
          col3_width = '80';
          col4_width = '215';
        }

        reportHeader = `<div class="topheader" style="margin-top: `+ topMarginHeader + `px;">
                      <table style="width: 680px;">
                          <tr>
                            <td style="width:`+col1_width+`px;border: 1px solid #000;">Name</td>
                            <td style="width:`+col2_width+`px;border: 1px solid #000;">`+patientData.patient_name+`</td>
                            <td style="width:`+col3_width+`px;border: 1px solid #000;">Age/Sex</td>
                            <td style="width:`+col4_width+`px;border: 1px solid #000;">`+patientData.patient_age.toString()+ patientData.patient_age_unit+`-`+patientData.patient_sex+`</td>
                          </tr>
                          <tr>
                            <td style="border: 1px solid #000;">Date</td>
                            <td style="border: 1px solid #000;">`+patientData.regn_date+`</td>
                            <td style="border: 1px solid #000;">Patient Id</td>
                            <td style="border: 1px solid #000;">`+patientData.patient_id_ref+`</td>
                          </tr>
                            <tr>
                            <td style="border: 1px solid #000;">Referring Doctor</td>
                            <td style="border: 1px solid #000;">`+patientData.refer_physician+`</td>
                            <td style="border: 1px solid #000;">Report Id</td>
                            <td style="border: 1px solid #000;">`+patientData.patient_id+`</td>
                          </tr>
                      </table>
                  </div>`;
      }
      
      var qrCodeValueinnerHTML = '';

      if (modality === 'CT' || modality === 'ct' || report_template_code === 'CT' 
      || patientData.center_key === 68 || patientData.center_key === 61 
      || patientData.center_key === 59 || patientData.center_key === 54
      || patientData.center_key === 33 || patientData.center_key === 66 
      || patientData.center_key === 39 || patientData.center_key === 58)
      {
        var qrCodeValue = document.getElementById(`qrCode_`+patientData.patient_key);
        if(qrCodeValue!==null){
          qrCodeValueinnerHTML = qrCodeValue.innerHTML;
        }
      }
      var qrCodeDisplay = '';
      if(qrCodeValueinnerHTML===''){
        qrCodeDisplay = 'none';
      }

      if (modality === 'CT' || modality === 'ct' || report_template_code === 'CT')
      {
        for(var iCnt = 0; iCnt<report_count; iCnt++){
          let reportValue = report_content;
          let pageBreakClass = 'bodytext';
          if(iCnt === report_count-1){
            pageBreakClass = '';
          }

          if(iCnt===1){
            reportValue = report_content2;
          }
          else if(iCnt===2){
            reportValue = report_content3;
          }
          else if(iCnt===3){
            reportValue = report_content4;
          }

          template3 += 
          `<div class="container `+pageBreakClass+`">
            <table id="report">`
              +reportHeader +`
              <tbody class="report-body">
                <tr style="height:20px;"><td colspan="4"></tr>
                <tr>
                  <td colspan="4">` 
                    + reportValue
                    + `
                  </td>
                </tr>

                <tr><td colspan="4"><br/><br/></tr>

                <tr>
                  <td colspan="4">
                    <div class="row">

                      <div class="col-6 margin-auto">
                        <div class="footsign" style="height:auto; width: 85px; background-color:lightgrey; padding:3px 3px 0px 3px;display:`+qrCodeDisplay+`;">
                          `+ qrCodeValueinnerHTML +`
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="footsign" style="text-align:center;">
                          <img src="`+ sign_url_data +`" width="229" height="48" /><br/>
                          <span><b>`+ dr_name +`</b></span> <br/>
                          `+ dr_design +`<br/>
                          `+ dr_regn +` <br/>
                        </div>
                      </div>

                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <div class="footer_text">
                        <span style="font-size:12px;"><b>Disclaimer:</b></span> It is an online interpretation of medical imaging based on clinical data. All modern machines
                        /procedures have their own limitation. If there is any clinical discrepancy this investigation may be repeated
                        or reassessed by other tests. Patient’s identification in online reporting is not established, So in no way can
                        this report be utilized for any medico legal purpose.
                    </div>
                  
                  </td>
                </tr>

              </tbody>

              <tfoot class="report-footer">
                <tr>
                    <th colspan="4" style="width:250px;bottom: `+ bottomMarginFooter + `px;">
                        &nbsp;
                    </th>
                </tr>
              </tfoot>
            </table>
          </div>`;
        }
    template3 += `
        </body>
        </html>`;
      }
      else{
        template3 += reportHeader + `<div class="bodyarea" style="top: `+ topMarginBodyArea + `px; bottom: `+ bottomMarginBodyArea + `px;">`;

        template3 += `<div`;
        if(report_count > 1){
        template3 += ` class="bodytext">`;
        }
        else{
        template3 += ` class="bodytextlast">`;
        }

        if (report_template_code === 'Template4')
        {
          template3 +=`<center class="mb-1"><span class="text-center font30 rptname">Radiological Observation</span></center>`;
        }

        template3 += report_content;
        template3 += `</div>`;

        if(report_count > 1)
        {
        template3 += `<div`;
        if(report_count > 2){
          template3 += ` class="bodytext">`;
        }
        else{
          template3 += ` class="bodytextlast">`;
        }
        template3 += report_content2;
        template3 += `</div>`;
        }
        if(report_count > 2)
        {
        template3 += `<div`;
        if(report_count > 3){
          template3 += ` class="bodytext">`;
        }
        else{
          template3 += ` class="bodytextlast">`;
        }
        template3 += report_content3;
        template3 += `</div>`;
        }
        if(report_count > 3)
        {
        template3 += `<div`;
        if(report_count > 4){
          template3 += ` class="bodytextlast">`;
        }
        else{
          template3 += ` class="bodytextlast">`;
        }
        template3 += report_content4;
        template3 += `</div>`;
        }
        template3 += `</div>`;

        template3 += `
            <footer class="divFooter" style="bottom: `+ bottomMarginFooter + `px;">
                <div class="row">
                    <div class="col-6 margin-auto">
                        <div class="footsign" style="height:auto; width: 85px; background-color:lightgrey; padding:3px 3px 0px 3px;display:`+qrCodeDisplay+`;">
                          `+ qrCodeValueinnerHTML +`
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="footsign">
                            <img src="`+ sign_url_data +`" width="229" height="48" /><br/>
                            <span><b>`+ dr_name +`</b></span> <br/>
                            `+ dr_design +`<br/>
                            `+ dr_regn +` <br/>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </body>
        </html>`;
      }
      
      return template3;
    }

    const EncryptPatientKey = (data) => {
        var CryptoJS = require("crypto-js");
        var ciphertext = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
        return ciphertext;
      }

      function PrintReport() {
        var template = '';
        var divReport = document.getElementById('divReport');
        if(divReport!=null){
            template = divReport.innerHTML;
        }
        var WinPrint = window.open('', '', 'width=850,height=600');
        WinPrint.document.write(template);
        WinPrint.document.close();
        WinPrint.focus();

        setTimeout(function() {
            WinPrint.print();
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
              setTimeout(function() {
                WinPrint.close();
              }, 30000);
            }
            else{
              WinPrint.close();
            }
        }, 600);        
      }
      
    return (
        <>
        <br></br>
                <button className="nav-link notification" id="printed-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="signedoff-tab-pane" aria-selected="false" onClick={event => PrintReport() }><small><b>Print Report </b></small>
                </button>
      
        <div id="divReport">
        </div>
        <div id={`qrCode_${patient_key.toString()}`} style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%", display: "none" }}>
            <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`https://tele.mytsofts.com/qrcodereport?data=${EncryptPatientKey(center_key.toString() + '~' + patient_key.toString())}`}
            viewBox={`0 0 256 256`}
            />
        </div>
        
        </>
    )
}
